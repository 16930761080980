import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./SideAds.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const SideAds = ({ link, title, img, author }) => {
  const [hidden, setHidden] = useState(true);
  return (
    hidden && (
      <div className={`container-side ${!hidden ? "hidden" : ""}`}>
        <Link to={link} target="_blank">
          <Button onClick={() => setHidden(false)}>
            <CloseIcon />
          </Button>
          <div className="side-title">
            <img src={img} alt={title} />
            <div className="over"></div>
          </div>
          <div>
            <h4>{title} </h4>
            <p dangerouslySetInnerHTML={{ __html: author }} />
          </div>
        </Link>
      </div>
    )
  );
};

export default SideAds;
