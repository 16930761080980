import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./SectionAds.scss";
import { Box } from "@mui/material";

const SectionAds = () => {
  const [displayedItems, setDisplayedItems] = useState<any>([]);

  const getRandomItems = () => {
    const shufled = [...itemData].sort(() => 0.5 - Math.random());
    return shufled.slice(0, 8);
  };

  useEffect(() => {
    setDisplayedItems(getRandomItems());

    const interval = setInterval(() => {
      setDisplayedItems(getRandomItems());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`container-section`}>
      <Box
        sx={{
          maxWidth: 1100,
          paddingInline: "1rem",
          gap: "10px !important",
          backgroundColor: "black",

          display: "grid",
          gridTemplateColumns: {
            md: "repeat(4, 1fr) !important",
            sm: "repeat(2, 1fr)",
          },
        }}
      >
        {displayedItems.map((item, index) => (
          <Link to={item.link} target="_blank">
            <img
              srcSet={`${item.img}`}
              src={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
            <div className="description">
              <h4>{item.title} </h4>
              <p dangerouslySetInnerHTML={{ __html: item.author }} />
            </div>
          </Link>
        ))}
      </Box>
    </div>
  );
};

const itemData = [
  {
    link: "https://divulgador.magalu.com/yy6NYPao",
    img: "https://a-static.mlcdn.com.br/800x560/smartphone-samsung-galaxy-s23-256gb-preto-5g-8gb-ram-61-cam-tripla-selfie-12mp/magazineluiza/232854100/3db64f5d28040178dfa05a7e6192552e.jpg",
    title: "Smartphone Samsung Galaxy S23 256GB",
    author: `<span class="value">De: R$ 6.499,00</span> - <br/>
                  <span>
                    R$ 3.332,00 em 10x <br/>de R$ 333,20 sem juros  <br/>ou R$ 2.998,80 no
                    Pix
                  </span>
                  <span class="descont">(10% de desconto no pix)</span>`,
  },
  {
    link: "https://divulgador.magalu.com/h4wWillv",
    img: "https://a-static.mlcdn.com.br/800x560/monitor-gamer-aoc-24-full-hd-hdmi-165hz-1ms-24g2s-bk-com-ajuste-de-altura/magazineluiza/237272800/957695a811984c74cf1d3e33db81365c.jpg",
    title: 'Monitor Gamer AOC 24" Full HD HDMI 165Hz 1ms 24G2S/BK',
    author: `<span class="value">De: R$ 999,00</span> - <br/>
                  <span>
                    R$ 887,80 em 7x de R$ 126,83 sem juros  <br/>ou R$ 799,02

no Pix

                  </span>
                  <span class="descont">(10% de desconto no pix)</span>`,
  },
  {
    link: "https://amzn.to/4eOFA7x",
    img: "https://m.media-amazon.com/images/I/51hfvRTYFWL._AC_SY450_.jpg",
    title: "Headset Gamer, Headphone, fone de ouvido com microfone ",
    author: `<span class="value">De: R$181,29 <br/></span> por
                  <span>
                    R$99,00 
                  </span>
                  <span class="descont">-45%</span>`,
  },
  {
    link: "https://amzn.to/3YzBVop",
    img: "https://m.media-amazon.com/images/I/61uOOR4-BZL._AC_SY450_.jpg",
    title: "Fone De Ouvido Tranya Nova com Cancelamento De Ruído(ANC)",
    author: `<span class="value">De: R$259,99 <br/></span>
                  <span>
                    R$207,99 Em até 4x <br/> R$ 52,02 sem juros
                  </span>
                  <span class="descont">-20%</span>`,
  },
  {
    link: "https://amzn.to/3UluvCG",
    img: "https://m.media-amazon.com/images/I/61dxj9a7NHL._AC_SY450_.jpg",
    title: "Tablet Lenovo Tab M9 Octa-Core 4GB 64GB Wi-Fi",
    author: `
                  <span>
                    R$809,10<br/>
                    ou R$ 866,92 em até 10x de R$ 86,71 sem juros
                  </span>
                  <span class="descont">à vista no Pix e boleto (6% off)</span>`,
  },
  {
    link: "https://divulgador.magalu.com/W_OMDT_J",
    img: "https://a-static.mlcdn.com.br/800x560/aparelho-full-box-streaming-mais-top-do-mercado-plus/estrelaimports/1250/0cf0063d5c8f91e3ec987b7fd90fb3fe.jpeg",
    title: "Aparelho Full Box Streaming Mais Top do Mercado - Plus",
    author: `R$ 140,51 no Pix`,
  },
  {
    link: "https://amzn.to/3A8UIgM",
    img: "https://m.media-amazon.com/images/I/61xAz257h0L._AC_SX679_.jpg",
    title:
      "Fire TV Stick 4K | Streaming com Dolby Vision/Atmos e suporte a wi-fi 6 | Com Alexa e comandos de TV",
    author: `<span class="value">De: R$449,00 <br/></span>
                  <span>
                    R$ 359,00 em até 12x de R$ 29,99 sem juros
                    ou R$323,10

no Pix
                  </span>
                  <span class="descont">à vista no Pix e boleto (10% off)</span>`,
  },
  {
    link: "https://amzn.to/4hcmZUm",
    img: "https://m.media-amazon.com/images/I/71puxwISAeL._AC_SX679_.jpg",
    title:
      "Projetor de luz de galáxia em forma de astronauta, projetor Space Buddy, luz noturna para quarto, controle remoto e temporizador",
    author: `R$67,98`,
  },
  {
    link: "https://amzn.to/4hx4k5I",
    img: "https://m.media-amazon.com/images/I/51w58z87mzL._AC_SX679_.jpg",
    title: "Integralmédica - Creatina Monohidratada 100% - 300g",
    author: `
      <span>
        R$69,90<br/>
       `,
  },
  {
    link: "https://amzn.to/4ftD0Ug",
    img: "https://m.media-amazon.com/images/I/61v+gedwgXL._AC_SX679_.jpg",
    title:
      "Celular Xiaomi Redmi 12C 3GB de RAM/64GB/Tela 6.71 /Dual Sim LTE - AZUL",
    author: `
      <span>
        R$697,89<br/>
        Em até 12x de R$ 65,13 com juros
       `,
  },
  {
    link: "https://amzn.to/3AsFrHW",
    img: "https://m.media-amazon.com/images/I/51y4q9jlkEL._AC_SX679_.jpg",
    title: "Smartphone Xiaomi Redmi 12C 128GB - 4GB de RAM ",
    author: `
      <span>
        R$745<br/>
        Em até 10x R$ 74,50 sem juros
       `,
  },
];
export default SectionAds;
