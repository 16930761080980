import React from "react";
import { fetchExternal } from "../../Services/index.ts";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const SectionTec = () => {
  const { data } = useQuery({
    queryKey: ["externalData"],
    queryFn: () => fetchExternal(),
  });
  const findAll = data && data?.findAllItens?.news[0];

  return (
    data && (
      <Card sx={{ maxWidth: 900, maxHeight: 400 }}>
        <CardMedia
          sx={{
            height: {
              md: 250,
              xs: 200,
            },
          }}
          image={findAll?.urlToImage || ""}
          title={findAll?.title}
        />
        <CardContent
          sx={{
            paddingBottom: 0,
            "@media (max-width:600px)": {
              paddingLeft: "5px",
            },
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              "@media (max-width:600px)": {
                fontSize: "inherit",
              },
            }}
          >
            {findAll?.title}
          </Typography>
        </CardContent>
        <CardActions sx={{ padding: 0 }}>
          <Button
            size="small"
            variant="text"
            sx={{
              marginInlineStart: "8px",
              marginBlockEnd: "5px",
              "@media (max-width:600px)": {
                margin: 0,
              },
            }}
          >
            <Link
              to={`${process.env.REACT_APP_LINK}/${findAll?.title
                .toLowerCase()
                .replace(/[#?&/]/g, "-")
                .replace(/ /g, "-")}/${findAll?.id}`}
              target="_blank"
              style={{
                color: "blue",
                fontSize: "16px",
              }}
            >
              Clique aqui para acessar a matéria completa!
            </Link>
          </Button>
        </CardActions>
      </Card>
    )
  );
};

export default SectionTec;
